//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AnalyticsInterface from '@/constants/mixpanel/AnalyticsInterface'

const { logSearchViewed } = AnalyticsInterface

export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localValue: this.value,
      focused: false,
      tempValue: '',
      timer: null,
    }
  },
  watch: {
    value(val) {
      this.localValue = val
      if (document.activeElement !== this.$refs.input && val.length < 1) {
        this.focused = false
      }
    },
  },
  mounted() {
    const search = this.$refs.input
    if (search) {
      search.addEventListener('keypress', this.handleKeyPress)
      search.addEventListener('keyup', this.handleKeyUp)
    }
  },
  beforeDestroy() {
    const search = this.$refs.input
    search.removeEventListener('keypress', this.handleKeyPress)
    search.removeEventListener('keyup', this.handleKeyUp)
  },
  methods: {
    handleKeyPress(e) {
      window.clearTimeout(this.timer)
    },
    handleKeyUp(e) {
      window.clearTimeout(this.timer) // prevent errant multiple timeouts from being generated
      this.timer = window.setTimeout(() => {
        if (this.$refs.input.value.length > 0)
          logSearchViewed(this.$refs.input.value)
        this.$emit('input', this.$refs.input.value)
      }, 1000)
    },
    focus() {
      this.focused = true
      this.$emit('focus')
      const interval = setInterval(() => {
        if (this.$refs.input) {
          clearInterval(interval)
          this.$refs.input.focus()
        }
      }, 100)
    },
    blur() {
      this.$emit('blur')
      if (this.$refs.input && this.$refs.input.value.length < 1) {
        this.focused = false
        this.localValue = ''
      }
    },
    updateValue() {
      // eslint-disable-next-line prefer-const
      let timer
      clearTimeout(timer)

      if (this.$refs.input && this.$refs.input.value === '') {
        timer = setTimeout(() => {
          if (this.$refs.input.value === '')
            this.$emit('input', this.$refs.input.value)
        }, 1000)
      }
    },
  },
}
