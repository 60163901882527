import { render, staticRenderFns } from "./NavbarNew.vue?vue&type=template&id=7ddc1054&"
import script from "./NavbarNew.vue?vue&type=script&lang=js&"
export * from "./NavbarNew.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavbarSessionIndicator: require('/codebuild/output/src1952670892/src/v3-UI/components/Navbar/SessionIndicator.vue').default,NavbarPatientName: require('/codebuild/output/src1952670892/src/v3-UI/components/Navbar/PatientName.vue').default,NavbarSearch: require('/codebuild/output/src1952670892/src/v3-UI/components/Navbar/Search.vue').default})
