import { render, staticRenderFns } from "./default.vue?vue&type=template&id=3f0c997f&scoped=true&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"
import style0 from "./default.vue?vue&type=style&index=0&id=3f0c997f&scoped=true&lang=css&"
import style1 from "./default.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f0c997f",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SidebarNew: require('/codebuild/output/src1952670892/src/v3-UI/components/SidebarNew.vue').default,NavbarNew: require('/codebuild/output/src1952670892/src/v3-UI/components/NavbarNew.vue').default,WaitingRoomCall: require('/codebuild/output/src1952670892/src/v3-UI/components/WaitingRoom/Call.vue').default,SearchContents: require('/codebuild/output/src1952670892/src/v3-UI/components/SearchContents.vue').default,ConfirmationPopup: require('/codebuild/output/src1952670892/src/v3-UI/components/ConfirmationPopup.vue').default,MainAnnotationLoader: require('/codebuild/output/src1952670892/src/v3-UI/components/Main/AnnotationLoader.vue').default,HelpVideosSideBar1: require('/codebuild/output/src1952670892/src/v3-UI/components/HelpVideos/SideBar1.vue').default})
