import { render, staticRenderFns } from "./default-new.vue?vue&type=template&id=0a975e4e&scoped=true&"
import script from "./default-new.vue?vue&type=script&lang=js&"
export * from "./default-new.vue?vue&type=script&lang=js&"
import style0 from "./default-new.vue?vue&type=style&index=0&id=0a975e4e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a975e4e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SidebarNew: require('/codebuild/output/src1952670892/src/v3-UI/components/SidebarNew.vue').default,NavbarNew: require('/codebuild/output/src1952670892/src/v3-UI/components/NavbarNew.vue').default,ConfirmationPopup: require('/codebuild/output/src1952670892/src/v3-UI/components/ConfirmationPopup.vue').default})
