//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import algoliasearch from 'algoliasearch/lite'
import IdleDetection from '@/modules/IdleDetection'
import { lightModes } from '@/constants/color'
import ExternalService from '@/services/modules/ExternalService'

export default {
  middleware: [
    'StepperMiddleware',
    'FirstLoginMiddleware',
    'BlockUserMiddleware',
    'FirstLoginDPFMiddleware',
    'TermsMiddleware',
    'VoiceMiddleware',
  ],
  colorMode: 'dark',
  data() {
    this.$root.searchClient = algoliasearch(
      this.$config.algoliaId,
      this.$config.algoliaSearchOnlyApiKey
    )
    const originalSearch = this.$root.searchClient.search
    this.$root.searchClient.search = function (...args) {
      return originalSearch.apply(this, args).catch((error) => {
        ExternalService.sentAlgoliaError({ message:error.message })
      })
    }

    return {
      searchClient: this.$root.searchClient,
      inWaitingRoom: false,
      timer: null,
      invalidPatient: false,
      length: 0,
      themeMode: true,
      nowRoute: '/',
    }
  },
  computed: {
    inCall() {
      return this.$store.state.meeting.currentMeeting != null
    },
    publishContentFilter() {
      if (this.$auth.user && this.$auth.user.preview_type === 1) {
        return ''
      } else {
        return 'is_publish=1'
      }
    },
  },
  watch: {
    $route: {
      handler(newVal, oldVal) {
        this.inWaitingRoom = newVal.fullPath.includes('waiting-room/call')
        this.$set(
          this,
          'inWaitingRoom',
          newVal.fullPath.includes('waiting-room/call')
        )
        this.nowRoute = newVal.path

        this.themeMode = lightModes.reduce(
          (acc, x) => acc || newVal.path.includes(x),
          false
        )
      },
      deep: true,
      immediate: true,
    },
    length: {
      handler(newVal, oldVal) {
        if (this.themeMode && newVal > 0) {
          document.body.classList.remove('light-theme')
        } else if (this.themeMode) {
          document.body.classList.add('light-theme')
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.$i18n.setLocale(this.$auth.user.address_country)
    IdleDetection.init(this.$root)
    const interval = setInterval(() => {
      if (this.$refs.confirmationPopup) {
        clearInterval(interval)
        this.$root.confirmationPopup = this.$refs.confirmationPopup
      }
      if (this.$refs.annotationLoader) {
        clearInterval(interval)
        this.$root.annotationLoader = this.$refs.annotationLoader
      }
    }, 1000)
    this.timer = setInterval(() => {
      this.inWaitingRoom = this.$route.fullPath.includes('waiting-room/call')
      this.invalidPatient = this.$platform.invalidPatient
    }, 10)
    setTimeout(() => {
      // this.checkPermission()
    }, 1000)

  },
  beforeDestroy() {
    IdleDetection.clearTimer()
    clearInterval(this.timer)
  },
  methods: {
    goToCall() {
      this.$router.push('/waiting-room/call')
    },
    async checkPermission() {
      if (this.$store.state.deviceType === 'mobile') {
        const permission = await this.checkMediaAccess()
        if (!(permission.haveMic && permission.haveCamera)) {
          this.setQueryPermission()
        }
      }
    },
    checkMediaAccess() {
      return new Promise((resolve, reject) => {
        navigator.mediaDevices.enumerateDevices().then((devices) => {
          let haveMic = false
          let haveCamera = false
          devices.forEach((device) => {
            if (device.kind === 'audioinput' && device.label) haveMic = true
            if (device.kind === 'videoinput' && device.label) haveCamera = true
          })
          resolve({
            haveMic,
            haveCamera,
          })
        })
      })
    },
    async setQueryPermission() {
      const result = await this.$root.confirmationPopup.confirmPopup({
        title: 'Are you sure ?',
        titleClass: 'has-text-centered',
        description: `Are you sure that you want to permission audio and video ?`,
        descriptionClass: 'has-text-centered',
        okText: 'Yes',
        cancelText: 'No',
        haveClose: false,
        dark: false,
      })

      if (!result) return

      navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then(
        (stream) => {
          console.log(stream)
        },
        (e) => {
          console.log(e)
        }
      )
    },
  },
}
