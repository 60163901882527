import AnalyticsManager from "./AnalyticsManager";

const EventObject = {
  Consultation: "Consultation",
  Content: "Content",
  Favourites: "Favourites",
  Search: "Search",
  Portfolio: "Portfolio",
  Login: "Login",
  Registration: "Registration",
  Tracking: "Tracking",
  Treatment: "Treatment",
  Profile: "Profile",
  Patient: "Patient",
  Dashboard: "Dashboard",
  Annotations: "Annotations",
  Meeting: "Meeting",
  Voice: "Voice",
  Snippet: 'Snippet',
  Template: 'Template',
};

const {
  Consultation,
  Content,
  Login,
  Registration,
  Tracking,
  Treatment,
  Patient,
  Meeting,
  Voice,
  Snippet,
  Template,
} = EventObject;

const EventAction = {
  Started: "Started",
  Completed: "Completed",
  Viewed: "Viewed",
  Selected: "Selected",
  Added: "Added",
  Sent: "Sent",
  Activated: 'Activated',
  Deactivated: 'Deactivated',
  Removed: "Removed",
  Scheduled: "Scheduled",
  Cancelled: "Cancelled",
  Joined: "Joined",
  Used: "Used",
  Opened: "Opened",
  Closed: "Closed",
  Deleted: "Deleted",
  Copied: "Copied",
  Generated: "Generated",
  Marked: "Marked",
  Displayed: "Displayed",
  Clicked: "Clicked",
  Edited: "Edited",
};

const {
  Started,
  Completed,
  Viewed,
  Selected,
  Added,
  Sent,
  Activated,
  Deactivated,
  Removed,
  Scheduled,
  Cancelled,
  Joined,
  Used,
  Opened,
  Closed,
  Deleted,
  Copied,
  Generated,
  Marked,
  Displayed,
  Clicked,
  Edited
} = EventAction;


const ConsultationModeList = {
  ManualStarted: "Manual Started",
  AftercareStarted: "Send Patient Information",
  AutoStarted: "Auto",
  Platform: "Auto",
  ManualCompleted: "Manual",
  AftercareCompleted: "Send Patient Information",
  NextPatient: "Next Patient",
  Virtual: "Virtual",
  TimeoutCompleted: "Timeout Completed"
};

const PlatformList = {
  Dentally: "DENTALLY",
  SOE: "SOE",
  Sensei: "SENSEI",
  Default: "Default",
};

const TreatmentDecisionList = {
  Confirmed: "Confirmed",
  Received: "Received",
  Rejected: "Rejected",
  Considering: "Considering"
};

const SentTypeList = {
  SMS: "SMS",
  Email: "Email",
};

const ThemeModeList = {
  DARK: "Dark",
  LIGHT: "Light",
};

const VoiceModeList = {
  OPENAI: "OpenAI",
  ASSEMBLYAI: "assemblyAI",
};

const VoiceCopyList = {
  Notes: "Notes",
  Letter: "Letter",
  Consent: "Consent",
};

const TrackingScreen = {
  Favourites: "Favourites",
  Search: "Search",
  Portfolio: "Portfolio",
  TrackingPage: "Tracking",
  Profile: "Profile",
  Dashboard: "Dashboard",
  Annotations: "Annotations",
};

const {
  Favourites,
  Search,
  Portfolio,
  TrackingPage,
  Profile,
  Dashboard,
  Annotations,
} = TrackingScreen;


function logConsultationStarted(consultationMode, platform) {
  // Check if the consultationMode is valid
  if (!Object.values(ConsultationModeList).includes(consultationMode)) {
    console.error('Invalid consultation mode:', consultationMode);
    return;
  }

  if (!Object.values(PlatformList).includes(platform)) {
    platform = PlatformList.Default
  }
  const properties = {
    ConsultationMode: consultationMode,
    Source: platform
  };
  AnalyticsManager.track(Consultation, Started, properties);
}

function logConsultationCompleted(consultationMode, duration) {
  // Check if the consultationMode is valid
  if (!Object.values(ConsultationModeList).includes(consultationMode)) {
    console.error("Invalid consultation mode:", consultationMode);
    return;
  }
  const properties = {
    Duration: duration,
    ConsultationMode: consultationMode
  };
  AnalyticsManager.track(Consultation, Completed, properties);
}

function logContentViewed(
  Condition,
  RiskCategory,
  Risks,
  SubCondition,
  SubTreatment,
  Treatment,
  TreatmentCategory,
  MediaType,
) {
  const properties = {
    Condition,
    "Risk Category": RiskCategory,
    Risks,
    "Sub Condition": SubCondition,
    "Sub Treatment": SubTreatment ?? Treatment,
    Treatment,
    "Treatment Category": TreatmentCategory,
    "Media Type": MediaType
  };
  AnalyticsManager.track(Content, Viewed, properties);
}

function logLoginCompleted() {

  const properties = {
    "Frequent User Page": true
  }
  AnalyticsManager.track(Login, Completed, properties);
}

function logRegistrationCompleted() {
  AnalyticsManager.track(Registration, Completed);
}

function logTreatmentSelected(treatmentDecision, description, rate) {
  // Check if the treatmentDecision is valid
  if (!Object.values(TreatmentDecisionList).includes(treatmentDecision)) {
    console.error("Invalid treatment decision:", treatmentDecision);
    return;
  }
  const properties = {
    "Treatment Decision": treatmentDecision,
    "State Description": description,
    "Rate": rate,
  };
  AnalyticsManager.track(Treatment, Selected, properties);
}

function logPatientAdded() {
  AnalyticsManager.track(Patient, Added);
}

function logTrackingSent(type) {

  // Check if the treatmentDecision is valid
  if (!Object.values(SentTypeList).includes(type)) {
    console.error("Invalid treatment decision:", type);
    return;
  }
  const properties = {
    "Type": type,
  };
  AnalyticsManager.track(Tracking, Sent, properties);
}

// Screen Tracking
function logFavouritesViewed() {
  AnalyticsManager.trackScreen(Favourites);
}

function logSearchViewed(term) {
  const properties = {
    "Search Term": term,
  };
  AnalyticsManager.trackScreen(Search, properties);
}

function logPortfolioViewed() {
  AnalyticsManager.trackScreen(Portfolio);
}

function logTrackingViewed() {
  AnalyticsManager.trackScreen(TrackingPage, {
    "Source": PlatformList.Default,
  });
}

function logAppointmentsViewed(platform) {

  if (!Object.values(PlatformList).includes(platform)) {
    platform = PlatformList.Default
  }
  AnalyticsManager.trackScreen(TrackingPage, {
    "Source": platform,
  });
}

function logProfileViewed() {
  AnalyticsManager.trackScreen(Profile);
}

function logDashboardViewed() {
  AnalyticsManager.trackScreen(Dashboard);
}

function logAnnotationsViewed() {
  AnalyticsManager.trackScreen(Annotations);
}

function logMeetingJoined() {
  AnalyticsManager.track(Meeting, Joined);
}

function logContentAdded() {
  AnalyticsManager.track(Content, Added);
}

function logContentRemoved() {
  AnalyticsManager.track(Content, Removed);
}

function logContentDeactivated() {
  AnalyticsManager.track(Content, Deactivated);
}

function logContentActivated() {
  AnalyticsManager.track(Content, Activated);
}

function logConsultationScheduled() {
  AnalyticsManager.track(Consultation, Scheduled);
}

function logConsultationCancelled() {
  AnalyticsManager.track(Consultation, Cancelled);
}

// Voice Tracking
function logVoiceViewed() {
  AnalyticsManager.trackScreen(Voice);
}

function logVoiceThemeModeViewed(type) {

  // Check if the ThemeMode is valid
  if (!Object.values(ThemeModeList).includes(type)) {
    console.error("Invalid ThemeMode decision:", type);
    return;
  }

  const properties = {
    "Theme": type,
  };
  AnalyticsManager.track(Voice, Used, properties);
}

function logVoiceStarted(type) {

  // Check if the  is valid
  if (!Object.values(VoiceModeList).includes(type)) {
    console.error("Invalid Voice mode decision:", type);
    return;
  }

  const properties = {
    "Voice Mode": type,
  };
  AnalyticsManager.track(Voice, Started, properties);
}

function logVoiceStopped(object) {
  AnalyticsManager.track(Voice, Completed, object);
}

function logVoiceSnippetOpened() {
  AnalyticsManager.track(Voice + ' ' + Snippet, Opened, {
    "Source": Snippet,
  });
}

function logVoiceSnippetAdded() {
  AnalyticsManager.track(Voice + ' ' + Snippet, Added, {
    "Source": Snippet,
  });
}

function logVoiceSnippetClosed() {
  AnalyticsManager.track(Voice + ' ' + Snippet, Closed, {
    "Source": Snippet,
  });
}

function logVoiceSnippetDeleted() {
  AnalyticsManager.track(Voice + ' ' + Snippet, Deleted, {
    "Source": Snippet,
  });
}

function logVoiceTemplateOpened() {
  AnalyticsManager.track(Voice + ' ' + Template, Opened, {
    "Source": Template,
  });
}

function logVoiceTemplateClosed() {
  AnalyticsManager.track(Voice + ' ' + Template, Closed, {
    "Source": Template,
  });
}

function logVoiceTemplateUsed() {
  AnalyticsManager.track(Voice + ' ' + Template, Opened, {
    "Source": Template,
  });
}

function logVoiceCopied(type) {

  // Check if the  is valid
  if (!Object.values(VoiceCopyList).includes(type)) {
    console.error("Invalid Voice copy type:", type);
    return;
  }

  const properties = {
    type,
  };

  AnalyticsManager.track(Voice, Copied, properties);
}

function logVoiceGenerated(object) {
  AnalyticsManager.track(Voice, Generated, object);
}

function logVoiceAuditDisplayed(object) {
  AnalyticsManager.track(Voice + ' Audit', Displayed, object);
}

function logVoiceAuditClicked() {
  AnalyticsManager.track(Voice + ' Audit', Clicked);
}

function logVoiceNADMarked() {
  AnalyticsManager.track(Voice + ' NAD', Marked);
}

function logVoiceEdited(type, initial, after, affected) {

  // Check if the  is valid
  if (!Object.values(VoiceCopyList).includes(type)) {
    console.error("Invalid Voice copy type:", type);
    return;
  }
  const properties = {
    type,
    initial,
    after,
    affected
  };

  AnalyticsManager.track(Voice, Edited, properties);
}

export default {
  EventObject,
  EventAction,
  ConsultationModeList,
  TrackingScreen,
  TreatmentDecisionList,
  SentTypeList,
  PlatformList,
  ThemeModeList,
  VoiceModeList,
  logConsultationStarted,
  logConsultationCompleted,
  logContentViewed,
  logLoginCompleted,
  logRegistrationCompleted,
  logTreatmentSelected,
  logPatientAdded,
  logTrackingSent,
  logFavouritesViewed,
  logSearchViewed,
  logPortfolioViewed,
  logTrackingViewed,
  logProfileViewed,
  logDashboardViewed,
  logAnnotationsViewed,
  logContentAdded,
  logMeetingJoined,
  logContentDeactivated,
  logContentActivated,
  logContentRemoved,
  logConsultationScheduled,
  logConsultationCancelled,
  logAppointmentsViewed,
  logVoiceViewed,
  logVoiceStarted,
  logVoiceThemeModeViewed,
  logVoiceStopped,
  logVoiceSnippetOpened,
  logVoiceSnippetAdded,
  logVoiceSnippetClosed,
  logVoiceSnippetDeleted,
  logVoiceTemplateOpened,
  logVoiceTemplateClosed,
  logVoiceTemplateUsed,
  logVoiceCopied,
  logVoiceGenerated,
  logVoiceNADMarked,
  logVoiceAuditDisplayed,
  logVoiceAuditClicked,
  logVoiceEdited
};
