//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MixpanelMixins from '@/mixins/MixpanelMixins'

export default {
  mixins: [MixpanelMixins],
  props: {
    refine: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      user: {
        id: this.$auth.user.id,
        name: this.$auth.user.first_name,
        profile_image: this.$auth.user.profile_image
      },
      isSideMenuShow: false,
      uA: navigator.userAgent,
      vendor: navigator.vendor,
      safariBrowser: true,
      userSubscription: {},
      pro_user: this.$auth.user.proUser,
      student_user: this.$auth.user.studentUser,
      new_user: this.$auth.user.newPlan,
      dynamicTrackingRoute: '',
    }
  },
  computed : {
    openNavLinksInANewTabs () {
     return this.$store.state.isVoiceNoteInProgress && this.$route.fullPath.includes('voice-note')
    }
  },
  mounted() {
    if (
      navigator.vendor.match(/apple/i) &&
      !navigator.userAgent.match(/crios/i) &&
      !navigator.userAgent.match(/fxios/i) &&
      !navigator.userAgent.match(/Opera|OPT\//)
    ) {
      this.safariBrowser = false
    }
    this.$root.$on('CSYD:UpdateImage', this.onUpdateImage)
    this.$root.$on('CSYD:SideMenuToggle', this.toggleSideMenu)

  },
  beforeDestroy() {
    this.$root.$off('CSYD:UpdateImage', this.onUpdateImage)
    this.$root.$off('CSYD:SideMenuToggle', this.toggleSideMenu)
  },
  methods: {
    toggleSideMenu() {
      this.isSideMenuShow = !this.isSideMenuShow
    },
    closeSideMenu() {
      this.refine()
      this.isSideMenuShow = false
      this.$root.$emit('CSYD:LandingPageReset')
    },
    fullscreenToggle() {
      if (
        !document.fullscreenElement && // alternative standard method
        !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen()
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen()
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          )
        }
      } else if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
      this.closeSideMenu()
    },
    async logout() {
      this.$echo.leave(`User.${this.$auth.user.id}`)
      await this.$auth.logout()
      this.trackOnMixpanel('User logged out')
      this.$router.replace('/')
    },
    onUpdateImage($event) {
      this.user.profile_image = $event
    },
  },
}
