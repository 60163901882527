var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"image is-narrow mr-0 avatar-comp",class:{ 'small p-0': _vm.small, 'tiny p-0': _vm.tiny }},[(!_vm.isLoaded)?_c('img',{class:{ border: _vm.border },staticStyle:{"width":"0px !important","height":"0px !important"},attrs:{"loading":"lazy","src":_vm.account.profile_image},on:{"load":_vm.onImgLoad}}):_vm._e(),_vm._v(" "),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.isLoaded)?_c('img',{staticClass:"is-rounded avatar",class:{
        notClickable: _vm.notClickable,
        border: _vm.border,
        selected: _vm.selected,
      },style:(_vm.width != 128
          ? ("width:" + _vm.width + "px !important;height:" + _vm.width + "px !important;")
          : ''),attrs:{"loading":"lazy","src":_vm.account.profile_image}}):_c('div',{staticClass:"\n        is-rounded\n        avatar\n        text\n        is-flex\n        has-text-weight-semibold\n        has-background-secondary\n        has-text-black\n        has-shadow\n        is-size-2\n      ",class:{
        notClickable: _vm.notClickable,
        border: _vm.border,
        selected: _vm.selected,
      },style:(_vm.width != 128
          ? ("width:" + _vm.width + "px !important;height:" + _vm.width + "px !important;font-size: " + ((15 / 28) * _vm.width) + "px !important;")
          : '')},[_vm._v("\n      "+_vm._s(_vm._f("firstLetter")(_vm.account.name))+"\n    ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }