
export const state = () => ({
  currentSession: null,
})

export const mutations = {
  // Create Current Session
  createSession(state, { session }) {
    if (!session) state.currentSession = null
    else state.currentSession = JSON.parse(JSON.stringify(session))
  },
  // Remove Current Session
  removeSession(state) {
    state.currentSession = null
  },
}
