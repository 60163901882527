//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment/src/moment'
import mixpanel from 'mixpanel-browser'
import clickOutside from 'buefy/src/directives/clickOutside'
import SessionService from '@/services/modules/SessionService'

import EducationService from '@/services/modules/EducationService'
import IdleDetection from '@/modules/IdleDetection'
import MeetingService from '@/services/modules/MeetingService'
import AnalyticsInterface from '@/constants/mixpanel/AnalyticsInterface'

const {
  ConsultationModeList,
  logConsultationCompleted,
  logConsultationStarted,
} = AnalyticsInterface

export default {
  components: {},

  directives: {
    clickOutside,
  },
  props: {
    isSearching: {
      type: Boolean,
      default: false,
    },
    inCall: {
      type: Boolean,
      default: false,
    },
    refineClear: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
      session: null,
      timer: null,
      timerTimer: null,
      utcTimeGap: 0,
      sendActiveTimer: null,
      inWaitingRoom: false,
      isSearchFocus: false,
      pro_user: this.$auth.user.proUser,
      student_user: this.$auth.user.studentUser,
      new_user: this.$auth.user.newPlan,
      voice_note_only: this.$auth.user?.extra_attribute?.voice_note_only||this.$auth.user.voicePlan,
      stopSessionState: true,
      isLoadingSessionBtn: false,
      isDarkMode: true,
    }
  },
  fetch() {
    if (
      (this.$can('tracking') && this.pro_user) ||
      this.student_user ||
      this.new_user
    ) {
      SessionService.ongoingSession().then((result) => {
        const ongoingSession = result.data.data
        if (!(ongoingSession instanceof Array)) {
          this.session = ongoingSession
        }
      })
    }
    if (
      this.$can('virtual' && this.pro_user) ||
      this.student_user ||
      this.new_user
    ) {
      MeetingService.getActive().then((result) => {
        const ongoingMeeting = result.data.data
        if (!(ongoingMeeting instanceof Array)) {
          this.$store.commit('meeting/joinMeeting', {
            meeting: ongoingMeeting,
          })
        }
      })
    }
  },
  computed: {
    currentMeeting() {
      return this.$store.state.meeting.currentMeeting
    },
  },
  watch: {
    $route(value) {
      if (value.fullPath.includes('voice-note') && !this.isDarkMode) {
        document.body.classList.add('light-theme')
      }
    },
    isDarkMode(val) {
      if (this.$route.fullPath.includes('voice-note')) {
        if (!val) {
          document.body.classList.add('light-theme')
        } else {
          document.body.classList.remove('light-theme')
        }
        localStorage.setItem('isDarkMode', this.isDarkMode)
      }
    },
    session: {
      handler(val, oldVal) {
        const haveCurrentMeeting =
          this.$store.state.meeting.currentMeeting != null
        if (val) {
          this.$store.commit('session/createSession', {
            session: val,
          })
          if (!haveCurrentMeeting) IdleDetection.resumeDetection()
          this.$root.$emit('CSYD:sessionStarted', val)
        } else {
          this.$store.commit('session/removeSession')
          if (!haveCurrentMeeting) IdleDetection.pauseDetection()
          this.$root.$emit('CSYD:sessionStopped', oldVal)
        }
      },
      immediate: true,
    },
  },
  mounted() {
    EducationService.getUTCTime().then((res) => {
      const utcTime = res.data
      this.utcTimeGap = moment.utc().diff(moment.utc(utcTime), 'seconds')
    })
    this.timerTimer = setInterval(() => {
      if (this.session) {
        this.timer = this.formatDuration(
          moment.utc().diff(moment.utc(this.session.start), 'seconds') -
            this.utcTimeGap
        )
      } else {
        this.timer = null
      }
      this.inWaitingRoom =
        window.location.pathname.includes('waiting-room/call')
    }, 500)
    this.$root.$on('CSYD:loadSession', this.loadSession)
    this.$root.$on('CSYD:idleDetected', this.idleDetected)
    this.$root.$on('CSYD:setSession', this.setSession)
    this.$root.$on('CSYD:stopSessionStateChange', this.stopSessionStateChange)
    this.$echo
      .join(`User.${this.$auth.user.id}`)
      .listen('.session.started', this.echoSessionStared)
    this.$echo
      .join(`User.${this.$auth.user.id}`)
      .listen('.session.stopped', this.echoSessionStopped)
    this.$echo
      .join(`User.${this.$auth.user.id}`)
      .listen('.whereby.left', this.onSomeonesLeft)
    this.$echo
      .join(`User.${this.$auth.user.id}`)
      .listen('.whereby.joined', this.onSomeonesJoin)
    this.$echo
      .join(`User.${this.$auth.user.id}`)
      .listen('.app.restart', this.onAppRefresh)
    this.$echo
      .join(`User.${this.$auth.user.id}`)
      .listen('.meeting.active', this.onActiveMeeting)
    this.$echo
      .join(`User.${this.$auth.user.id}`)
      .listen('.meeting.clear', this.onClearActiveMeeting)
    this.$echo
      .join(`User.${this.$auth.user.id}`)
      .listen('.meeting.cancelled', this.onMeetingCancelled)
    this.$echo
      .join(`User.${this.$auth.user.id}`)
      .listenForWhisper('notIdle', (e) => {
        if (window.idleId !== e.id) {
          window.idleGraceTime = 60 * 2
        }
      })
    this.sendActiveTimer = setInterval(() => {
      if (window.idleDetection && !window.idle && window.idleGraceTime === 0) {
        this.$echo.join(`User.${this.$auth.user.id}`).whisper('notIdle', {
          id: window.idleId,
        })
      }
    }, 1000 * 60 * 2)

    setTimeout(() => {
      if(localStorage.getItem('isDarkMode') !== null) {
        this.isDarkMode = localStorage.getItem('isDarkMode') === 'true'
      }
    }, 1000)
  },
  beforeDestroy() {
    this.$echo.leave(`User.${this.$auth.user.id}`)
    clearInterval(this.timerTimer)
    clearInterval(this.sendActiveTimer)
    this.$root.$off('CSYD:loadSession', this.loadSession)
    this.$root.$off('CSYD:idleDetected', this.idleDetected)
    this.$root.$off('CSYD:setSession', this.setSession)
    this.$root.$off('CSYD:stopSessionStateChange', this.stopSessionStateChange)
  },
  methods: {
    navigateToVoiceNote() {
      this.$router.push('/voice-note')
    },
    stopSessionStateChange(state) {
      this.stopSessionState = state
    },
    clickOutside() {
      if (this.$refs.navbar) {
        this.$refs.navbar.internalIsActive = false
      }
    },
    onAppRefresh() {
      window.location.reload()
    },
    onMeetingCancelled(event) {
      this.$root.$emit('CSYD:MeetingCanceled', event)
      const meeting = event.meeting
      if (
        this.$store.state.meeting.currentMeeting &&
        this.$store.state.meeting.currentMeeting.id === meeting.id
      ) {
        this.$store.commit('meeting/leaveMeeting')
        this.$root.$emit('CSYD:ClearActiveMeeting', event)
        if (window.location.pathname.includes('waiting-room/call')) {
          this.$router.push('/waiting-room')
        }
      }
    },
    goToCall() {
      this.$router.push('/waiting-room/call')
    },
    onActiveMeeting(event) {
      if (!this.$store.state.meeting.currentMeeting) {
        this.$store.commit('meeting/joinMeeting', {
          meeting: event.meeting,
        })
        this.$root.$emit('CSYD:ActiveMeeting', event)
      }
    },
    onClearActiveMeeting(event) {
      if (this.$store.state.meeting.currentMeeting) {
        this.$store.commit('meeting/leaveMeeting')
        this.$root.$emit('CSYD:ClearActiveMeeting', event)
        if (window.location.pathname.includes('waiting-room/call')) {
          this.$router.push('/waiting-room')
        }
      }
    },
    onSomeonesJoin(event) {
      this.$root.$emit('CSYD:SomeoneJoined', event)
      document.getElementById('bell').play()
      this.successNotify(`
        <b class='is-block mb-1'>New Patient Notification</b>
        You got a new patient
      `)
    },
    onSomeonesLeft(event) {
      this.$root.$emit('CSYD:SomeoneLeft', event)
    },
    echoSessionStared(event) {
      this.session = event.session
    },
    echoSessionStopped(event) {
      this.session = null
      if (this.$route.fullPath.startsWith('/content')) {
        this.$router.replace('/landing')
      }
    },
    async idleDetected() {
      if (this.session && !this.currentMeeting) {
        await this.stopSession('auto')
      }
    },
    loadSession(state) {
      this.isLoadingSessionBtn = state
    },
    setSession(session) {
      this.session = session
      this.$store.commit('session/createSession', session)
    },
    clickSendEmail() {
      mixpanel.track(`"send e-mail" option on the dashboard was clicked `, {
        type: this.type,
        id: this.id,
        name: this.name,
      })
    },
    async nextPatient() {
      const result = await this.$root.confirmationPopup.confirmPopup({
        title: 'Ready to move onto your next patient?',
        titleClass: 'has-text-centered',
        description: 'This will close your current session.',
        descriptionClass: 'has-text-centered',
        okText: 'End session',
        cancelText: 'Cancel',
        haveClose: false,
      })
      if (!result) {
        mixpanel.track(`Clicked to continue session`, {
          type: this.type,
          id: this.id,
          name: this.name,
        })
      }
      if (!result) return
      await this.stopSession('NextPatient')
      await this.startSession('NextPatient')
    },
    async startSession(type = 'manual') {
      if (this.isLoading) return
      if (this.$route.fullPath.startsWith('/appointments')) {
        this.$router.replace('/landing')
      }
      if (!(this.pro_user || this.student_user || this.new_user)) return
      this.isLoading = true
      try {
        const sessionData = (await SessionService.create()).data.data
        if (sessionData.message) {
          this.isLoading = false
          return
        }
        this.session = sessionData

        // mixpanel.track(`Start button clicked`, {
        //   id: this.session.id,
        //   name: this.session.name,
        //   start: this.session.start,
        // })
        if (type === 'NextPatient') {
          logConsultationStarted(ConsultationModeList.NextPatient)
        } else {
          logConsultationStarted(ConsultationModeList.ManualStarted)
        }
      } catch (error) {}
      this.isLoading = false
    },
    async stopSession(type = 'manual') {
      if (this.isLoading) return
      if (!(this.pro_user || this.student_user || this.new_user)) return

      this.isLoading = true
      try {
        if (
          this.$route.fullPath.startsWith('/content') ||
          this.$route.fullPath.endsWith('/view')
        ) {
          this.$root.$emit('CSYD:animationStopped')
          this.$router.replace('/landing')
        }
        // mixpanel.track(`Clicked to end session`, {
        //   id: this.session.id,
        //   // name: this.currentSession.name,
        //   name: 'Anonymous',
        //   start: this.session.start,
        // })
        const sessionData = (await SessionService.stopSession(this.session.id))
          .data.data

        const startDate = new Date(sessionData.start)
        const endDate = new Date(sessionData.end)

        const durationSeconds = (endDate - startDate) / 1000
        if (type === 'manual') {
          logConsultationCompleted(
            ConsultationModeList.ManualCompleted,
            durationSeconds
          )
        } else if (type === 'NextPatient') {
          logConsultationCompleted(
            ConsultationModeList.NextPatient,
            durationSeconds
          )
        } else {
          logConsultationCompleted(
            ConsultationModeList.TimeoutCompleted,
            durationSeconds
          )
        }
      } catch (error) {}
      this.session = null
      this.isLoading = false
    },
    formatDuration(period) {
      const parts = []
      const duration = moment.duration(period, 'seconds')
      if (!duration || duration.toISOString() === 'P0D') return
      // if (duration.years() >= 1) {
      //   const years = Math.floor(duration.years())
      //   parts.push(years + ' ' + (years > 1 ? 'years' : 'year'))
      // }
      // if (duration.months() >= 1) {
      //   const months = Math.floor(duration.months())
      //   parts.push(months + ' ' + (months > 1 ? 'months' : 'month'))
      // }
      if (duration.days() >= 1) {
        const days = Math.floor(duration.days())
        parts.push(days + ' ' + (days > 1 ? 'd' : 'd'))
      }
      if (duration.hours() >= 1) {
        const hours = Math.floor(duration.hours())
        parts.push(
          String(hours).padStart(2, '0') + ' ' + (hours > 1 ? 'h' : 'h')
        )
      }
      // if (duration.minutes() >= 1) {
      const minutes = Math.floor(duration.minutes())
      parts.push(
        String(minutes).padStart(2, '0') + ' ' + (minutes > 1 ? 'min' : 'min')
      )
      // }

      const seconds = Math.floor(duration.seconds())
      parts.push(
        String(seconds).padStart(2, '0') + ' ' + (seconds > 1 ? 'sec' : 'sec')
      )

      return parts.join(' ')
    },
    async nameUpdate($event) {
      if (!(this.pro_user || this.student_user || this.new_user)) return
      this.isLoading = true
      try {
        await SessionService.update(this.session.id, { name: $event })
        this.session.name = $event
        this.$root.$emit('CSYD:SessionNameUpdate', this.session)
        this.setSession(this.session)
      } catch (error) {}
      this.isLoading = false
    },
  },
}
