export default function ({
  $auth,
  redirect,
  route
}) {
  const user = $auth.user;

  if (user && !user.is_admin) {
    const extra = user.extra_attribute;

    const noVoiceNote = extra && ('voice_note' in extra  && 'voice_note_only' in extra)
      && (!extra.voice_note && !extra.voice_note_only);

    if (noVoiceNote && route.path.includes('voice-note')) {
      return redirect('/landing');
    }
  }
}
