//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  filters: {
    firstLetter(str) {
      return str ?str.charAt(0).toUpperCase():''
    },
  },
  props: {
    account: {
      type: Object,
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
    border: {
      type: Boolean,
      default: false,
    },
    tiny: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '128',
    },
    selected: {
      type: Boolean,
      default: false,
    },
    notClickable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoaded: false,
    }
  },
  mounted() {

    this.$root.$on('CSYD:UpdateImage', this.onUpdateImage)
  },
  methods: {
    onImgLoad() {
      this.isLoaded = true
    },
    onUpdateImage(event) {
      this.isLoaded = true
      this.$emit('account', {
        profile_image: event,
      })
    },
  },
}
