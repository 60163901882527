import Echo from 'laravel-echo'

window.Pusher = require('pusher-js')

// eslint-disable-next-line no-undef
// Pusher.logToConsole = true
// eslint-disable-next-line no-undef
Pusher.Runtime.createXHR = () => {
  const xhr = new window.XMLHttpRequest()
  xhr.withCredentials = true
  return xhr
}

export default function({ $config, store }, inject) {
  window.Echo = new Echo({
    broadcaster: 'pusher',
    authEndpoint: `${$config.baseURL}/broadcasting/auth`,
    key: $config.pusherKey,
    cluster: $config.pusherCluster,
    forceTLS: true
  })

  // window.Echo.channel('App').listen('.app.restart', (e) => {
  //   window.location.reload(true)
  // })

  // window.Echo.channel('App').listen('.app.content.restart', (e) => {
  //   if (window.location.pathname === '/landing' || window.location.pathname === '/landing/') {
  //     setTimeout(() => {
  //       window.location.reload(true)
  //     }, 1000 * 10)
  //   } else {
  //     store.commit('clear')
  //   }
  // })

  inject('echo', window.Echo)
}
